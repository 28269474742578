import { _userLevel } from 'ecp/models/UsersModel'
import { IUserPermission } from 'egi/repositories/PermissionsRepository'
import { IAuth } from 'egi/types'
import translate from 'utils/translate'

export const PermissionModel = {

  canUpdatePermissions (userLevel: _userLevel): boolean {
    return ['master'].includes(userLevel)
  },

  hasParceiroTelevendas (permissions: Array<IUserPermission>): boolean {
    return permissions.some(permission => permission.slug === 'parceiro-televendas')
  },

  canSimulateWithoutLimits (user: IAuth, permissions: Array<IUserPermission>): boolean {
    // TODO - CHANGE when RODO-2116 is done
    // return this.hasParceiroTelevendas(permissions)
    return ['operatorFuncao', 'corbanFuncao'].includes(user.level) || this.hasParceiroTelevendas(permissions)
  },

  translateLevel (level: _userLevel, permissions: Array<IUserPermission>): string {
    if (this.hasParceiroTelevendas(permissions)) {
      return `${translate.level(level)} (Televendas)`
    }

    return translate.level(level)
  },

  hasChatRoute (userEmail: string): boolean {
    const allowedEmails = [
      'roger.frias@rodobens.com.br',
      'pedro.ferraz@rodobens.com.br',
      'mariana.molina@rodobens.com.br',
      'suporte@agxsoftware.com'
    ]

    return allowedEmails.includes(userEmail)
  },

  hasChatRouteAndTelevendasRoutes (permissions: Array<IUserPermission>, userEmail: string): boolean {
    return this.hasChatRoute(userEmail) && this.hasParceiroTelevendas(permissions)
  }
}
